import React, { useEffect, useRef } from "react";
import Footer from "./Footer";
import "../css/about.css";
import Header from "./Header";

function About() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((err) => {
        console.log("Error trying to play video: ", err);
      });
    }
  }, []);
  return (
    <div className="main-container">
      <Header />
      <div className="about_box">
        <div className="about1_box">
          <img alt="" src="/head/about1.png" />
        </div>

        <div className="about2_box">
          <span class="description-section-body">
            <p>
              <strong>
                Hearst is one of the nation’s largest global, diversified
                information, services and media companies.
              </strong>
            </p>

            <p>
              Hearst has been innovating for more than a century, leading with
              purpose, integrity and a culture of care, with a mission to inform
              audiences and improve lives.
            </p>

            <p>
              The company’s diverse portfolio includes global financial services
              leader Fitch Group; Hearst Health, a group of medical information
              and services businesses; Hearst Transportation, which includes
              CAMP Systems International, a major provider of
              software-as-a-service solutions for managing maintenance of jets
              and helicopters; ownership in cable television networks such as
              A&amp;E, HISTORY, Lifetime and ESPN; 35 television stations; 24
              daily and 52 weekly newspapers; digital services businesses; and
              more than 200&nbsp;magazine editions around the world.
            </p>

            <p>
              Hearst is always moving forward, investing in healthcare solutions
              to improve patient outcomes and technology that curbs emissions;
              providing vital analysis, data and software to the global
              financial services industry; delivering important service and
              investigative journalism; and inspiring audiences with sports and
              entertainment programming.
            </p>

            <p>
              With a commitment to maintaining the highest quality in its
              products and services, Hearst is dedicated to serving the
              communities it operates in, both civically and philanthropically.
            </p>
          </span>
        </div>

        <div className="about3_box">
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/347934/Hearst.com_AboutPage_Leadership-Type_BrandCard_1276x960.jpg/5e94f208-5764-c233-d17a-b864640d5f80?t=1569444851051"
            />
          </div>
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/0/V1_TimelineTile_Hearst.comCPR8000cc_Option2.jpg/2a58fd71-5327-5e10-246d-92163f8fcb96?t=1651500365055"
            />
            <div class="col-four-text">
              <div class="front-card">
                <p>Story of Hearst</p>
              </div>
            </div>
          </div>
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/0/V2_RISE+Hearst.com+Tile_ESG0001E+%281%29.jpg/d51ecb22-8dc4-56ae-486c-3011391d5732?t=1708968319133"
            />
            <div class="col-four-text">
              <div class="front-card">
                <p>Diversity, Equity & Inclusion</p>
              </div>
            </div>
          </div>
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/104883/HomePageCard_HearstLife_1276x960_SS_V1-1.jpg/20378208-586b-88b8-5b59-ab84d40dc51c?t=1612218650946"
            />
            <div class="col-four-text">
              <div class="front-card">
                <p>#HearstLife</p>
              </div>
            </div>
          </div>
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/0/Hearst%40135Thumbnail.jpg/8e4f8cc2-5acb-67f7-3012-99c94ec001b7?t=1707325627489"
            />
            <div class="col-four-text">
              <div class="front-card">
                <p>Hearst @ 135</p>
              </div>
            </div>
          </div>
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/347934/V1HearstLab_BrandCard_1276x960_CPR7919.jpg/65547c46-e8dc-4043-1405-d63a782d026d?t=1638831742093"
            />
            <div class="col-four-text">
              <div class="front-card">
                <p>HearstLab</p>
              </div>
            </div>
          </div>
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/0/V2_CPR8266C_LevelUpVentures_Hearst.com_BrandCard_C2.jpg/72792c6d-14ba-8537-edf3-53202ed3fb32?t=1662493792072"
            />
            <div class="col-four-text">
              <div class="front-card">
                <p>Level Up Ventures</p>
              </div>
            </div>
          </div>
          <div className="about3_box_inner ">
            <img
              alt=""
              src="https://www.hearst.com/documents/33329/104883/Homepage_Map_1914x1440.jpg_update.jpg/52685f56-3318-7fa0-3610-caf6da1a9fbd?t=1565889472277"
            />
            <div class="col-four-text">
              <div class="front-card">
                <p>Hearst Around the World</p>
              </div>
            </div>
          </div>
        </div>

        <div className="about4_box">
          <h2>Annual Reviews</h2>
          <div className="about4_box_flex">
            <div className="about4_box_inner">
              <img
                alt=""
                src="https://www.hearst.com/documents/33329/0/CPR8800h_Hearst.com_Tile_C22.jpg/d0e719f8-90fc-8012-a673-65319f41b5a6?t=1706197204448"
              />
              <div class="brand-inner-card">
                <h2 class="black-main-cutline" style={{ color: "#fff" }}>
                  2023
                </h2>
              </div>
            </div>
            <div className="about4_box_inner">
              <img
                alt=""
                src="https://www.hearst.com/documents/33329/0/V2_CPR8400h_Hearst.com+Tile_c4-AboutPageAnnualTile+%281%29.png/92cfc296-5d2c-695b-dbd8-9e66a4d134a9?t=1676558715994"
              />
              <div class="brand-inner-card">
                <h2 class="black-main-cutline" style={{ color: "#fff" }}>
                  2022
                </h2>
              </div>
            </div>
            <div className="about4_box_inner">
              <img
                alt=""
                src="https://www.hearst.com/documents/33329/0/V15_Hearst.com_About+Page_Annual+Tile_.png/1541cbad-9078-2770-f0b2-2937c5b0a2b1?t=1643816697755"
              />
              <div class="brand-inner-card">
                <h2 class="black-main-cutline" style={{ color: "#fff" }}>
                  2021
                </h2>
              </div>
            </div>
            <div className="about4_box_inner">
              <img
                alt=""
                src="https://www.hearst.com/documents/33329/884770/CPR7480_Annual+Review+2020_Hearst.com_1276x960_AboutPage_Card.jpg/f5617b37-4dcf-0340-2c8b-d4d40f21a439?t=1610747970234"
              />
              <div class="brand-inner-card">
                <h2 class="black-main-cutline" style={{ color: "#fff" }}>
                  2020
                </h2>
              </div>
            </div>
            <div className="about4_box_inner">
              <img
                alt=""
                src="https://www.hearst.com/documents/33329/347978/Annual18_BrandCard_1276x960.jpg/c7fff949-74b7-d5ad-b54d-40548b5dd9b4?t=1559063548093"
              />
              <div class="brand-inner-card">
                <h2 class="black-main-cutline" style={{ color: "#fff" }}>
                  2018
                </h2>
              </div>
            </div>
            <div className="about4_box_inner">
              <img
                alt=""
                src="https://www.hearst.com/documents/33329/347978/Annual+review_Image+2_1276x960.png_update.jpg/49d8dfe3-a1ff-e1cf-58fd-e1a3d7376ca0?t=1562722005229"
              />
              <div class="brand-inner-card">
                <h2 class="black-main-cutline" style={{ color: "#fff" }}>
                  2017
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
