import "./App.css";
import Main from "./Component/Main";
import About from "./Component/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "./Component/Privacy";
import Contact from "./Component/Contact";
import Terms from "./Component/Terms";
import Subscribe from "./Component/Subscribe";
import Decorate from "./Component/Decorate";
import Rooms from "./Component/Rooms";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />{" "}
        <Route path="/rooms" element={<Rooms />} />{" "}
        <Route path="/design" element={<Decorate />} />{" "}
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
}

export default App;
